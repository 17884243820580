<template>
  <div class="hold-transition">
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Tarifarios</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Hidrocarburos</li>
                  <li class="breadcrumb-item active">Tarifarios</li>
                  <li class="breadcrumb-item active">
                    Formulario {{ accion }}
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid">
            <div class="card">
              <div class="card-header pt-2 pb-2">
                <div class="col-3 pt-2 pb-2">
                  <h5 class="mb-0">Formulario {{ accion }} Tarifarios</h5>
                </div>
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="form-group col-md-3">
                    <label>Nombre</label>
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      v-model="form.nombre"
                      :class="
                        $v.form.nombre.$invalid
                          ? 'is-invalid'
                          : 'is-valid'
                      "
                      :disabled="!$store.getters.can('hidrocarburos.tarifarios.edit')"
                    />
                  </div>
                  <div class="form-group col-md-3">
                    <label>Fecha inicial</label>
                    <input
                      type="date"
                      class="form-control form-control-sm"
                      v-model="form.fecha_inicio"
                      :class="
                        $v.form.fecha_inicio.$invalid
                          ? 'is-invalid'
                          : 'is-valid'
                      "
                      @input="validaFechas"
                      :disabled="!$store.getters.can('hidrocarburos.tarifarios.edit')"
                    />
                  </div>
                  <div class="form-group col-md-3">
                    <label>Fecha final</label>
                    <input
                      type="date"
                      class="form-control form-control-sm"
                      v-model="form.fecha_final"
                      :class="
                        $v.form.fecha_final.$invalid ? 'is-invalid' : 'is-valid'
                      "
                      @input="validaFechas"
                      :disabled="!$store.getters.can('hidrocarburos.tarifarios.edit')"
                    />
                  </div>
                  <div class="form-group col-md-3">
                    <label>Estado</label>
                    <select
                      class="form-control form-control-sm"
                      v-model="form.estado"
                      :class="
                        $v.form.estado.$invalid ? 'is-invalid' : 'is-valid'
                      "
                      :disabled="this.estado_tarifario == 2 || !$store.getters.can('hidrocarburos.tarifarios.edit')"
                    >
                      <option value="">Seleccione...</option>
                      <option
                        v-for="estado in listasForms.estados"
                        :key="estado.numeracion"
                        :value="estado.numeracion"
                      >
                        {{ estado.descripcion }}
                      </option>
                    </select>
                  </div>
                  <div class="form-group col-md-3">
                    <label>Usuario</label>
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      :value="responsable.nombre"
                      disabled
                    />
                    <input
                      v-model="form.user_id"
                      type="hidden"
                      class="form-control form-control-sm p-0"
                    />
                  </div>
                  <div class="form-group col-md-6">
                    <label for="observaciones">Observaciones</label>
                    <br />
                    <textarea
                      vtype="date"
                      class="form-control"
                      id="fecha_final"
                      v-model="form.observaciones"
                      rows="2"
                      :class="
                        $v.form.observaciones.$invalid
                          ? 'is-invalid'
                          : 'is-valid'
                      "
                      :disabled="!$store.getters.can('hidrocarburos.tarifarios.edit')"
                    ></textarea>
                  </div>
                </div>
              </div>
              <div class="card-body" v-if="form.id">
                <TarifarioAsignarTarifa
                  ref="TarifarioAsignarTarifa"
                ></TarifarioAsignarTarifa>
              </div>
              <div class="card-footer">
                <div class="row">
                  <button class="btn bg-secondary col-md-1" @click="back()">
                    <i class="fas fa-reply"></i><br />Volver
                  </button>
                  <button
                    class="btn bg-primary col-md-1"
                    v-show="!$v.form.$invalid"
                    @click="save()"
                    v-if="
                      ($store.getters.can('hidrocarburos.tarifarios.create') ||
                        $store.getters.can('hidrocarburos.tarifarios.edit')) &&
                      this.estado_tarifario != 2
                    "
                  >
                    <i class="fas fa-paper-plane"></i><br />Guardar
                  </button>
                  <button
                    class="btn bg-primary col-md-1"
                    data-target="#Modal_empresas"
                    data-toggle="modal"
                    v-if="
                      $store.getters.can('hidrocarburos.tarifarios.edit') &&
                      estado_tarifario == 2
                    "
                  >
                    <i class="fas fa-paperclip"></i><br /><small
                      >Asignar Empresas</small
                    >
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
    <TarifarioAsignarEmpresa
      ref="TarifarioAsignarEmpresa"
      v-if="estado_tarifario == 2"
    ></TarifarioAsignarEmpresa>
  </div>
</template>

<script>
import axios from "axios";
import { required } from "vuelidate/lib/validators";
import TarifarioAsignarTarifa from "./TarifarioAsignarTarifa";
import TarifarioAsignarEmpresa from "./TarifarioAsignarEmpresa";

export default {
  name: "TarifarioForm",
  components: {
    TarifarioAsignarTarifa,
    TarifarioAsignarEmpresa,
  },
  data() {
    return {
      accion: "",
      metodo: "",
      estado_tarifario: null,
      form: {
        id: null,
        nombre: null,
        fecha_inicio: null,
        fecha_final: null,
        estado: null,
        observaciones: null,
        user_id: this.$store.getters.getUser.id,
      },
      responsable: {
        id: this.$store.getters.getUser.id,
        nombre: this.$store.getters.getUser.name,
      },
      listasForms: {
        estados: [],
      },
    };
  },
  validations: {
    form: {
      nombre: {
        required,
      },
      fecha_inicio: {
        required,
      },
      fecha_final: {
        required,
      },
      estado: {
        required,
      },
      observaciones: {
        required,
      },
    },
  },
  methods: {
    getIndex() {
      this.accion = this.$route.params.accion;
      if (this.$route.params.data_edit) {
        this.estado_tarifario = this.$route.params.data_edit.estado;
      }
      if (this.$route.params.accion == "Editar") {
        this.getForm();
        this.metodo = "PUT";
      } else {
        this.metodo = "POST";
      }
    },

    getForm() {
      this.form = {
        id: this.$route.params.data_edit.id,
        nombre: this.$route.params.data_edit.nombre,
        fecha_inicio: this.$route.params.data_edit.fecha_inicio,
        fecha_final: this.$route.params.data_edit.fecha_final,
        estado: this.$route.params.data_edit.estado,
        observaciones: this.$route.params.data_edit.observaciones,
        user_id: this.$route.params.data_edit.user.id,
      };
    },

    getEstados() {
      axios.get("/api/lista/28").then((response) => {
        this.listasForms.estados = response.data;
      });
    },

    validaFechas() {
      if (this.form.fecha_inicio && this.form.fecha_final) {
        const fecha_menor = new Date(this.form.fecha_inicio);
        const fecha_mayor = new Date(this.form.fecha_final);
        // Se valida que la fecha inicial sea menor que la fecha final
        if (fecha_menor > fecha_mayor) {
          this.form.fecha_final = null;
          this.$swal({
            icon: "error",
            title: `La fecha inicial no puede ser mayor a la fecha final...`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        }
      }
    },

    save() {
      if (!this.$v.form.$invalid) {
        axios({
          method: this.metodo,
          url: "/api/hidrocarburos/tarifarios",
          data: this.form,
        })
          .then((response) => {
            this.form = response.data;
            this.estado_tarifario = this.form.estado;
            this.$swal({
              icon: "success",
              title: "El tarifario se guardó exitosamente...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
            if (this.metodo == "PUT") {
              this.$refs.TarifarioAsignarTarifa.getIndex();
            }
          })
          .catch((e) => {
            this.$swal({
              icon: "error",
              title: "Ocurrió un error, vuelva a intentarlo... " + e,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    back() {
      return this.$router.replace("/Hidrocarburos/Tarifarios");
    },
  },
  mounted() {
    this.getIndex();
    this.getEstados();
  },
};
</script>
