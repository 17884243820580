var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"hold-transition"},[_c('section',{staticClass:"content-header"},[_c('div',{staticClass:"row"},[_vm._m(0),_c('div',{staticClass:"col-md-6 text-right"},[(_vm.estado == 1)?_c('button',{staticClass:"btn btn-info",attrs:{"data-target":"#Modal_tarifas","data-toggle":"modal"}},[_vm._v(" Agregar Tarifas ")]):_vm._e()])])]),_c('section',{staticClass:"content"},[_c('div',{staticClass:"row"},[_c('table',{staticClass:"table table-sm table-hover"},[_vm._m(1),_c('tbody',_vm._l((_vm.lista_tarifas),function(tarifa){return _c('tr',{key:tarifa.id},[_c('td',[_vm._v(_vm._s(tarifa.nombre))]),_c('td',[_vm._v(_vm._s(tarifa.ruta.nombre))]),_c('td',[_vm._v(_vm._s(tarifa.tipo_vehiculo.nombre))]),(tarifa.producto_liquido)?_c('td',[_vm._v(" "+_vm._s(tarifa.producto_liquido.nombre)+" ")]):_c('td'),_c('td',{staticClass:"text-right"},[_vm._v(_vm._s(tarifa.detalles_tarifa[0].tarifa))]),_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(tarifa.detalles_tarifa[0].fecha_inicio_vigencia)+" ")]),_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(tarifa.detalles_tarifa[0].fecha_fin_vigencia)+" ")]),_c('td',[_c('div',{staticClass:"btn-group float-right"},[(_vm.estado == 1)?_c('button',{staticClass:"btn btn-sm btn-danger",staticStyle:{"float":"right"},on:{"click":function($event){return _vm.save(tarifa.id)}}},[_c('i',{staticClass:"fas fa-trash"})]):_vm._e()])])])}),0)])])]),_c('div',{staticClass:"modal fade",attrs:{"id":"Modal_tarifas","tabindex":"-1","role":"dialog","aria-labelledby":"exampleModalLabel","aria-hidden":"true"}},[_c('div',{staticClass:"modal-dialog",attrs:{"role":"document"}},[_c('div',{staticClass:"modal-content"},[_vm._m(2),_c('div',{staticClass:"modal-body"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"exampleInputEmail1"}},[_vm._v("Asignar Tarifa")]),_c('v-select',{staticClass:"form-control form-control-sm p-0",class:[
                _vm.$v.form.tarifa.$invalid ? 'is-invalid' : 'is-valid',
                _vm.$store.getters.getDarkMode ? 'dark-vselect' : '',
              ],attrs:{"placeholder":"Tarifa","label":"nombre","options":_vm.listasForms.lista_tarifas,"filterable":false},on:{"search":_vm.buscarTarifas},model:{value:(_vm.form.tarifa),callback:function ($$v) {_vm.$set(_vm.form, "tarifa", $$v)},expression:"form.tarifa"}})],1)]),_c('div',{staticClass:"modal-footer"},[_c('button',{staticClass:"btn btn-secondary",attrs:{"type":"button","data-dismiss":"modal"}},[_vm._v(" Cerrar ")]),_c('button',{directives:[{name:"show",rawName:"v-show",value:(
              _vm.$store.getters.can('hidrocarburos.tarifarios.edit') &&
                !_vm.$v.form.$invalid
            ),expression:"\n              $store.getters.can('hidrocarburos.tarifarios.edit') &&\n                !$v.form.$invalid\n            "}],staticClass:"btn btn-primary",attrs:{"type":"button"},on:{"click":function($event){return _vm.save(_vm.form.tarifa.id, 'guardar')}}},[_vm._v(" Guardar ")])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-6"},[_c('h5',[_vm._v("Tarifas")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('thead',{staticClass:"thead-dark"},[_c('tr',[_c('th',[_vm._v("Tarifa")]),_c('th',[_vm._v("Ruta")]),_c('th',[_vm._v("Tipo de Vehículo")]),_c('th',[_vm._v("Producto Líquido")]),_c('th',[_vm._v("Valor")]),_c('th',[_vm._v("Inicio Vigencia")]),_c('th',[_vm._v("Fin Vigencia")]),_c('th',[_vm._v("Acciones")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"modal-header bg-frontera-top-left pt-2 pb-2"},[_c('h5',{staticClass:"modal-title text-white",attrs:{"id":"exampleModalLabel"}},[_vm._v(" Agregar Tarifas ")]),_c('button',{staticClass:"close",attrs:{"type":"button","data-dismiss":"modal","aria-label":"Close"}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("×")])])])
}]

export { render, staticRenderFns }