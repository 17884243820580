<template>
  <div class="hold-transition">
    <section class="content-header">
      <div class="row">
        <div class="col-md-6">
          <h5>Tarifas</h5>
        </div>
        <div class="col-md-6 text-right">
          <button
            data-target="#Modal_tarifas"
            data-toggle="modal"
            class="btn btn-info"
            v-if="estado == 1"
          >
            Agregar Tarifas
          </button>
        </div>
      </div>
    </section>
    <section class="content">
      <div class="row">
        <table class="table table-sm table-hover">
          <thead class="thead-dark">
            <tr>
              <th>Tarifa</th>
              <th>Ruta</th>
              <th>Tipo de Vehículo</th>
              <th>Producto Líquido</th>
              <th>Valor</th>
              <th>Inicio Vigencia</th>
              <th>Fin Vigencia</th>
              <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="tarifa in lista_tarifas" :key="tarifa.id">
              <td>{{ tarifa.nombre }}</td>
              <td>{{ tarifa.ruta.nombre }}</td>
              <td>{{ tarifa.tipo_vehiculo.nombre }}</td>
              <td v-if="tarifa.producto_liquido">
                {{ tarifa.producto_liquido.nombre }}
              </td>
              <td v-else></td>
              <td class="text-right">{{ tarifa.detalles_tarifa[0].tarifa }}</td>
              <td class="text-center">
                {{ tarifa.detalles_tarifa[0].fecha_inicio_vigencia }}
              </td>
              <td class="text-center">
                {{ tarifa.detalles_tarifa[0].fecha_fin_vigencia }}
              </td>
              <td>
                <div class="btn-group float-right">
                  <button
                    class="btn btn-sm btn-danger"
                    style="float: right"
                    v-if="estado == 1"
                    @click="save(tarifa.id)"
                  >
                    <i class="fas fa-trash"></i>
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </section>
    <div
      class="modal fade"
      id="Modal_tarifas"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h5 class="modal-title text-white" id="exampleModalLabel">
              Agregar Tarifas
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group">
              <label for="exampleInputEmail1">Asignar Tarifa</label>
              <v-select
                :class="[
                  $v.form.tarifa.$invalid ? 'is-invalid' : 'is-valid',
                  $store.getters.getDarkMode ? 'dark-vselect' : '',
                ]"
                v-model="form.tarifa"
                placeholder="Tarifa"
                label="nombre"
                class="form-control form-control-sm p-0"
                :options="listasForms.lista_tarifas"
                :filterable="false"
                @search="buscarTarifas"
              ></v-select>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Cerrar
            </button>
            <button
              type="button"
              class="btn btn-primary"
              v-show="
                $store.getters.can('hidrocarburos.tarifarios.edit') &&
                  !$v.form.$invalid
              "
              @click="save(form.tarifa.id, 'guardar')"
            >
              Guardar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import vSelect from "vue-select";
import { required } from "vuelidate/lib/validators";
import $ from "jquery";

export default {
  name: "TarifarioAsignarTarifa",
  components: {
    vSelect,
  },
  data() {
    return {
      id: null,
      estado: null,
      lista_tarifas: [],
      tarifas_asignadas: [],
      listasForms: {
        lista_tarifas: [],
      },
      form: {
        tarifa: {},
      },
    };
  },
  validations: {
    form: {
      tarifa: {
        required,
      },
    },
  },
  methods: {
    async getIndex() {
      this.id = this.$parent.form.id;
      await axios
        .get("/api/hidrocarburos/tarifarios/show/" + this.id)
        .then((response) => {
          if (response.data.length > 0) {
            let result = response.data[0];
            this.lista_tarifas = result.tarifas;
            this.estado = this.$parent.estado_tarifario;
            this.getTarifasAsigandas();
          }
        });
    },

    getTarifasAsigandas() {
      this.lista_tarifas.forEach((element) => {
        this.tarifas_asignadas.push(element.id);
      });
    },

    // Se buscan los datos para filtrar las tatifas existentes en el tarifario
    buscarTarifas(search, loading) {
      if (search != "") {
        let me = this;
        let tarifas_asignadas = this.tarifas_asignadas;
        loading(true);
        var url = "api/hidrocarburos/tarifas/lista?nombre=" + search;
        axios
          .get(url, {
            params: {
              tarifas_asignadas,
            },
          })
          .then(function(response) {
            me.listasForms.lista_tarifas = response.data;
            loading(false);
          })
          .catch(function(error) {
            this.$swal({
              icon: "error",
              title: "Ocurrio un error" + error,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    async save(tarifa_id, accion = null) {
      let title = null;
      let form = {
        accion,
        tarifario_id: this.id,
        tarifa_id,
      };
      if (accion) {
        title = "La tarifa se guardó exitosamente...";
      } else {
        title = "La tarifa se eliminó exitosamente...";
      }
      await axios({
        method: "PUT",
        url: "/api/hidrocarburos/tarifarios/assingOrRevokeTarifa/" + this.id,
        data: form,
      })
        .then((response) => {
          $("#Modal_tarifas").hide();
          $("body").removeClass("modal-open");
          $(".modal-backdrop").remove();
          this.form.tarifa = {};
          this.listasForms.lista_tarifas = [];
          this.lista_tarifas = [];
          this.tarifas_asignadas = [];
          this.getIndex();
          this.$swal({
            icon: "success",
            title,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        })
        .catch((e) => {
          this.$swal({
            icon: "error",
            title: "Ocurrió un error, vuelva a intentarlo..." + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },
  },
  mounted() {
    this.getIndex();
  },
};
</script>
